import React from 'react'
import _ from 'lodash'
import { Paper, InputBase, Divider, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { ActionTypes } from '../../context/actionTypes'
import { useGlobalState } from '../../context/GlobalDataContext'
import { Dispatch, SearchParams } from '../../common/types'
import { useSearchState, useSearchDispatch, searchItems } from '../../context/SearchContext'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon: {
    padding: 10,
    fill: 'rgba(0, 0, 0, 0.54)',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const debouncedSearch = _.debounce(async (dispatch: Dispatch, ctInstance: any, params: SearchParams) => {
  if (params.searchText !== '') {
    searchItems(dispatch, ctInstance, params)
  } else {
    dispatch({ type: ActionTypes.SET_SEARCH_RESULTS_UPDATED, payload: [] })
  }
}, 1000)

export const SearchBox = () => {
  const globalState = useGlobalState()
  const state = useSearchState()
  const dispatch = useSearchDispatch()
  const classes = styles()
  const { searchText } = state
  const params = globalState.sdk.params.instance
  const placeholderText = params.placeHolderText || 'Search'

  const search = (event) => {
    const text = !_.isUndefined(event.target.value) ? _.trim(event.target.value) : _.trim(searchText)
    dispatch({ type: ActionTypes.SET_SEARCH_TEXT, payload: text })
    debouncedSearch(dispatch, globalState.ctInstance, {
      searchText: text,
      pages: state.pages,
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.search}>
        <InputBase
          value={searchText}
          className={classes.input}
          placeholder={placeholderText}
          inputProps={{ 'aria-label': 'search' }}
          onChange={search}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <Search className={classes.icon} />
      </Paper>
    </div>
  )
}

export default SearchBox
