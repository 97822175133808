import React from 'react'
import PriceFilter from '../PriceFilter/PriceFilter'
import StandardFilter from '../StandardFilter/StandardFilter'
import Sort from '../Sort/Sort'
import { Facet, Labels } from '../../../common/types'
import { currencyFormatPartial } from '../../../utils/common'
import { SortConfig } from '../../../common/types'
import classes from './Filter.module.scss'

type Props = Facet & {
  labels?: Labels
  showMore?: boolean
  showMoreLimit?: number
  limit?: number
  sortConfig?: SortConfig
}

export const generatePriceRanges = (currency: string) => {
  const ranges = [
    { end: 20 },
    { start: 21, end: 30 },
    { start: 31, end: 40 },
    { start: 41, end: 50 },
    { start: 51, end: 75 },
    { start: 76 },
  ]
  return ranges.map((range) => ({
    ...range,
    label: range.end
      ? `${currencyFormatPartial(range.start || 0.0, 2, currency)} - ${currencyFormatPartial(range.end, 2, currency)}`
      : `${currencyFormatPartial(range.start || 76, 2, currency)}+`,
  }))
}

const Filter = (props: Props) => {
  return (
    <div>
      <hr className="hr"></hr>
      <div className={classes.filterTitle}>{props.name}</div>
      {props.component === 'sort' ? (
        <Sort
          {...props}
          items={props.sortConfig.items}
          defaultRefinement={props.defaultRefinement || props.sortConfig.defaultRefinement}
        />
      ) : props.component === 'price' ? (
        <PriceFilter {...props} items={generatePriceRanges(props.currency || 'GBP')} />
      ) : (
        <StandardFilter {...props} />
      )}
    </div>
  )
}

export default Filter
