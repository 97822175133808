import React, { useEffect } from 'react'

import { theme } from '../theme'
import { makeStyles, Container } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core'

import SearchBox from './SearchBox/SearchBox'
import ProductsGrid from './ProductsGrid/ProductsGrid'
import SelectedProducts from './SelectedProducts/SelectedProducts'
import { useGlobalDispatch, useGlobalState, initialiseState } from '../context/GlobalDataContext'
import ShowError from '../../common/ShowError/ShowError'
import { Initialise } from '../common/types'
import { SearchProvider } from '../context/SearchContext'
import { ActionTypes } from '../context/actionTypes'

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const CommercetoolsProductSelector = ({ sdk, ctInstance, params }: Initialise) => {
  const classes = styles()
  const dispatch = useGlobalDispatch()
  const state = useGlobalState()

  useEffect(() => {
    initialiseState({ dispatch, sdk, ctInstance, params })
    sdk.frame.startAutoResizer()
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <Container className={classes.root}>
        <ShowError error={state.error} onClose={() => dispatch({ type: ActionTypes.SET_ERROR, payload: null })} />
        {state.sdk && (
          <>
            <SelectedProducts />
            <SearchProvider>
              <SearchBox />
              <ProductsGrid />
            </SearchProvider>
          </>
        )}
      </Container>
    </MuiThemeProvider>
  )
}

export default CommercetoolsProductSelector
