import { ActionTypes } from '../actionTypes'
import { Action, SearchResults, Pages } from '../../common/types'

export type SearchState = {
  searchLoading: boolean
  pages: Pages
  searchText: string
  searchResults: SearchResults
  searchError: string
}

export const searchReducer = (state: SearchState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_LOADING: {
      return { ...state, searchLoading: action.payload }
    }
    case ActionTypes.SET_SEARCH_RESULTS_UPDATED: {
      return { ...state, searchResults: action.payload }
    }
    case ActionTypes.SET_PAGES: {
      return { ...state, pages: action.payload }
    }
    case ActionTypes.SET_SEARCH_TEXT: {
      return { ...state, searchText: action.payload }
    }
    case ActionTypes.SET_SEARCH_ERROR: {
      return { ...state, searchError: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
