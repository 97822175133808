import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { motion } from 'framer-motion'
import { makeStyles, CircularProgress, Paper, Typography } from '@material-ui/core'

import { ReactSortable, SortableEvent } from 'react-sortablejs'
import FadeIn from '../../../common/FadeIn/FadeIn'
import Product from '../Product/Product'
import FormError from '../FormError/FormError'
import './SelectedProducts.scss'
import { ActionTypes } from '../../context/actionTypes'
import { useGlobalState, useGlobalDispatch, activateSave } from '../../context/GlobalDataContext'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '200px',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  dragItem: {
    transition: 'flex 0.15s, opacity 0.15s',
  },
  title: {
    fontWeight: 700,
  },
  items: {
    margin: 'auto',
    width: '100%',
    zIndex: 2,
    display: 'grid',
    gridTemplateColumns: '100%',
    '@media(min-width: 450px)': {
      gridTemplateColumns: '50% 50%',
    },
    '@media(min-width: 800px)': {
      gridTemplateColumns: '25% 25% 25% 25%',
    },
    '@media(min-width: 1024px)': {
      gridTemplateColumns: '20% 20% 20% 20% 20%',
    },
  },
  errorWrapper: {
    height: '20px',
    marginTop: 'auto',
  },
  loader: {
    margin: 'auto',
    alignSelf: 'center',
  },
}))

export const SelectedProducts = () => {
  const state = useGlobalState()
  const dispatch = useGlobalDispatch()
  const { sdk, selectedItems, params, loading, touched } = state
  const [isDragging, setDragging] = useState(false)
  const { allowMultiple } = params
  const { minItems, maxItems } = _.get(sdk, 'field.schema', {})
  const { readOnly } = _.get(sdk, 'form', {})
  const classes = styles({ readOnly })
  const showMinItemError = touched && minItems && selectedItems.length < minItems
  const showMaxItemError = touched && maxItems && selectedItems.length > maxItems
  const defaultTitle = allowMultiple ? 'Selected products' : 'Selected product'

  useEffect(() => {
    if (touched) {
      activateSave({ sdk, dispatch, selectedItems, allowMultiple })
    }
  }, [selectedItems, touched])

  const ProductList = () => {
    return selectedItems.map((item) => <Product item={item} variant="removable" key={item.key} />)
  }

  const reorderItems = (evt: SortableEvent) => {
    dispatch({
      type: ActionTypes.REORDER_ITEMS,
      payload: { oldIndex: evt.oldIndex, newIndex: evt.newIndex },
    })
    dispatch({ type: ActionTypes.SET_TOUCHED, payload: true })
    setDragging(false)
  }

  return (
    <Paper className={'selected-products ' + classes.root} data-testid="selected-products">
      <Typography variant="subtitle1" component="h2" className={classes.title}>
        {_.get(params.SDK, 'field.schema.title', defaultTitle)}
      </Typography>

      <Loading show={loading} className={classes.loader} />

      <FadeIn show={!loading}>
        {readOnly && <div className={classes.items}>{ProductList}</div>}
        {!readOnly && !loading && (
          <ReactSortable
            animation={150}
            className={classes.items}
            ghostClass="productPlaceholder"
            onStart={() => setDragging(true)}
            onEnd={(evt: SortableEvent) => reorderItems(evt)}
            list={selectedItems}
            setList={() => {
              return null
            }}
          >
            {selectedItems.map((item) => (
              <motion.div positionTransition={isDragging ? null : { type: 'tween' }} key={item.id}>
                <Product item={item} variant="removable" />
              </motion.div>
            ))}
          </ReactSortable>
        )}
      </FadeIn>
      {!selectedItems.length && (
        <Typography component="div" variant="body1" className="text">
          No items selected
        </Typography>
      )}

      <div className={classes.errorWrapper}>
        <FormError show={Boolean(showMinItemError)}>You must select a minimum of {minItems} items</FormError>
        <FormError show={Boolean(showMaxItemError)}>You must select a maximum of {maxItems} items</FormError>
      </div>
    </Paper>
  )
}

const Loading = ({ show, className }: { show: boolean; className: string }) => (
  <FadeIn
    show={show}
    className={className}
    exitOptions={{ position: 'absolute', zIndex: 3, top: '50%', marginTop: '-20px' }}
  >
    <CircularProgress />
  </FadeIn>
)

export default SelectedProducts
