import React from 'react'
import { FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import FadeIn from '../../../common/FadeIn/FadeIn'

const styles = makeStyles(() => ({
  error: {
    marginTop: '10px',
  },
}))

type Props = {
  show: boolean
  children: React.ReactNode
}

const FormError = ({ show = true, children }: Props) => {
  const classes = styles()

  return (
    <FadeIn show={show}>
      <FormHelperText error={true} className={classes.error}>
        {children}
      </FormHelperText>
    </FadeIn>
  )
}

export default FormError
