import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import { useGlobalDispatch, useGlobalState, saveFacets } from '../../../context/GlobalDataContext'

type Props = {
  items: string[]
  refine: (value: string[]) => void
}

export const ClearFilters = ({ items, refine }: Props) => {
  const state = useGlobalState()
  const dispatch = useGlobalDispatch()
  const clearSelected = (items: string[]) => {
    refine(items)
    saveFacets({ state, dispatch, attribute: '', value: '' })
  }

  return <>{!!items.length && <button onClick={() => clearSelected(items)}>Clear all filters</button>}</>
}

export default connectCurrentRefinements(ClearFilters)
