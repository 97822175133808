import React from 'react'
import { ActionTypes } from './actionTypes'
import { Dispatch, Facet } from '../common/types'
import { globalReducer, GlobalState } from './global.reducer'

const initialState = {
  sdk: null,
  facetFilters: [],
  facets: [],
  query: '',
  loading: false,
  error: null,
  params: null,
  initialised: false,
  filterChanged: false,
  sortConfig: null,
  indexName: '',
}

type GlobalProviderProps = { children: React.ReactNode }

type ActivateSave = {
  sdk: any
  dispatch: Dispatch
  facets: Facet[]
  query: string
  indexName: string
}

type SaveFacets = {
  state: {
    sdk: any
    query: string
    facets: Facet[]
    indexName: string
  }
  dispatch: Dispatch
  attribute: string
  value: string
}

const GlobalStateContext = React.createContext<GlobalState | undefined>(undefined)
const GlobalDispatchContext = React.createContext<Dispatch | undefined>(undefined)

async function activateSave(config: ActivateSave) {
  const { sdk, dispatch, facets, query, indexName } = config
  try {
    const value = {
      facetFilters: facets,
      query,
      indexName,
    }
    await sdk.field.setValue(value)
    console.log('Amplience SDK: setValue', value)
  } catch (error) {
    dispatch({ type: ActionTypes.SET_ERROR, payload: error })
  }
}

async function saveFacets({ state, dispatch, attribute, value }: SaveFacets) {
  const { sdk, query, facets, indexName } = state
  let currentIndexName = indexName
  let newFacets = []

  if (attribute === '') {
    dispatch({ type: ActionTypes.SET_FACETS, payload: '' })
  } else {
    const newFacet = {
      name: attribute,
      attribute,
      currentRefinement: value,
      label: value,
    }
    newFacets = facets.filter((item) => item.name !== attribute)
    newFacets.push(newFacet)
    dispatch({ type: ActionTypes.SET_FACETS, payload: newFacets })
    if (attribute === 'sortBy') {
      currentIndexName = value
      dispatch({ type: ActionTypes.SET_INDEX_NAME, payload: value })
    }
  }
  activateSave({ sdk, dispatch, facets: newFacets, query, indexName: currentIndexName })
}

function DataProvider({ children }: GlobalProviderProps) {
  const [state, dispatch] = React.useReducer(globalReducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

const useGlobalState = () => {
  const context = React.useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalDataContext')
  }
  return context
}

const useGlobalDispatch = () => {
  const context = React.useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalDispatchContext')
  }
  return context
}

export { DataProvider, useGlobalState, useGlobalDispatch, activateSave, saveFacets }
