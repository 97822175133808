import React from 'react'
import { connectSortBy } from 'react-instantsearch-dom'
import { FilterItem, Refinements } from '../../../common/types'
import { useGlobalDispatch, useGlobalState, saveFacets } from '../../../context/GlobalDataContext'

type Props = Pick<Refinements, 'items' | 'refine' | 'attribute'>

export const Sort = ({ items, refine, attribute }: Props) => {
  const state = useGlobalState()
  const dispatch = useGlobalDispatch()

  const itemSelected = (item: FilterItem) => {
    refine(item.value)
    saveFacets({ state, dispatch, attribute, value: item.value })
  }

  return (
    <ul className="filter">
      {items &&
        items.map((item: FilterItem) => (
          <li key={item.label}>
            <input
              data-testid={`sort-${item.value}`}
              type="radio"
              value={item.value}
              checked={item.isRefined}
              onChange={() => {
                itemSelected(item)
              }}
            />
            {item.label}
          </li>
        ))}
    </ul>
  )
}

export default connectSortBy(Sort)
