import { useConvertExperiences } from 'extensions/ExperimentSelector/hooks/useConvertExperiences'
import React from 'react'
import { Props } from '../../common/types'
import ExperienceField from './ExperienceField'
import styles from './ExperimentSelector.module.scss'
import VariationField from './VariationField'

export const ExperimentSelector = ({ amplienceSdk }: Props) => {
  const isExperienceField = amplienceSdk.params.instance.type === 'experimentId'
  const { experiences } = useConvertExperiences(amplienceSdk)
  console.log({ experiences })

  return (
    <div className={styles.container}>
      {isExperienceField ? (
        <ExperienceField amplienceSdk={amplienceSdk} experiences={experiences} />
      ) : (
        <VariationField amplienceSdk={amplienceSdk} experiences={experiences} />
      )}
    </div>
  )
}

export default ExperimentSelector
