import React from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'

import Pager from '../Pager/Pager'
import FadeIn from '../../../common/FadeIn/FadeIn'
import Product from '../Product/Product'
import PaginationSummary from '../PaginationSummary/PaginationSummary'
import { useSearchState } from '../../context/SearchContext'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  items: {
    display: 'grid',
    gridTemplateColumns: '100%',
    justifyContent: 'space-between',
    width: '100%',
    '@media(min-width: 450px)': {
      gridTemplateColumns: '50% 50%',
    },
    '@media(min-width: 800px)': {
      gridTemplateColumns: '25% 25% 25% 25%',
    },
    '@media(min-width: 1024px)': {
      gridTemplateColumns: '20% 20% 20% 20% 20%',
    },
  },
  loader: {
    margin: theme.spacing(2),
  },
}))

export const ProductsGrid = () => {
  const classes = styles()
  const state = useSearchState()
  const { searchResults, searchLoading, searchText } = state

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <FadeIn show={!!searchResults.length && !searchLoading}>
            <PaginationSummary />
          </FadeIn>
        </Grid>
      </Grid>

      <FadeIn show={searchLoading} exitOptions={{ position: 'absolute' }}>
        <CircularProgress className={classes.loader} />
      </FadeIn>

      <div className={classes.items}>
        {!searchLoading && searchResults.map((item) => <Product key={item.id} item={item} />)}
      </div>

      <FadeIn show={!!searchResults.length && !searchLoading}>
        <Pager searchText={searchText} />
      </FadeIn>
    </div>
  )
}

export default ProductsGrid
