import { CommercetoolsApi, ProductProjection, Region } from '@gradientedge/commercetools-utils'
import { ProductSelectorError } from './ProductSelectorError'
import { SearchParams } from '../common/types'

export default class Commercetools {
  public readonly locale: string
  public readonly pageSize: number
  public readonly ctApi: CommercetoolsApi

  constructor({ projectKey, clientId, clientSecret, locale, pageSize }) {
    this.ctApi = new CommercetoolsApi({
      clientId,
      clientSecret,
      systemIdentifier: 'ui-extensions',
      projectKey,
      region: Region.EUROPE_GCP,
      clientScopes: ['view_products'],
    })

    this.locale = locale
    this.pageSize = pageSize
  }

  async getItems(filterKeys = []) {
    if (!filterKeys.length) {
      return []
    }
    try {
      const keysString = filterKeys.map((key) => `"${key}"`).join(',')
      const response = await this.ctApi.queryProductProjections({
        where: `key in (${keysString})`,
        limit: 100,
      })
      return this.parseResults(response.results)
    } catch (e) {
      console.error(e)
      throw new ProductSelectorError('Could not get items', ProductSelectorError.codes.GET_SELECTED_ITEMS)
    }
  }

  parseResults(data: ProductProjection[]) {
    return data.map((item) => {
      let image: string | null = null
      if (Array.isArray(item?.masterVariant?.images) && item.masterVariant.images.length) {
        image = item.masterVariant.images[0].url
      }
      return {
        id: item.id,
        key: item.key,
        name: item.name[this.locale],
        image,
      }
    })
  }

  async search(state: SearchParams) {
    const { searchText, pages } = state

    try {
      const { results, total } = await this.ctApi.searchProductProjections({
        params: {
          staged: false,
          offset: pages.currentPage * this.pageSize,
          limit: this.pageSize,
          [`text.${this.locale}`]: searchText,
        },
      })

      return {
        items: this.parseResults(results),
        pages: {
          numPages: Math.ceil(total / this.pageSize),
          currentPage: pages.currentPage,
          total,
        },
      }
    } catch (e) {
      console.error(e)
      throw new ProductSelectorError('Could not search', ProductSelectorError.codes.GET_ITEMS)
    }
  }
}
