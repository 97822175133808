export default new (function () {
  this.field = {
    getValue: () => {
      console.info('Amplience SDK: getValue')
      return ''
    },
    setValue: (value) => {
      console.info('Amplience SDK: setValue:', value)
    },
  }

  this.frame = {
    startAutoResizer: () => {
      console.info('Amplience SDK: startAutoResizer')
    },
  }

  this.form = {
    readOnly: false,
  }
})()
