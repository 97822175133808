export default new (function () {
  this.field = {
    getValue: () => {
      console.info('Amplience SDK: getValue')
      return {
        facetFilters: this.params.facets,
      }
    },
    setValue: (value) => {
      console.info('Amplience SDK: setValue:', value)
    },
  }

  this.frame = {
    startAutoResizer: () => {
      console.info('Amplience SDK: startAutoResizer')
    },
  }

  this.form = {
    readOnly: false,
  }

  this.params = {
    instance: {
      indexName: process.env.REACT_APP_AQS_INDEX_NAME,
      appId: process.env.REACT_APP_AQS_APP_ID,
      apiKey: process.env.REACT_APP_AQS_APP_KEY,
      sortConfig: {
        defaultRefinement: 'dev_product_gb_price_asc',
        items: [
          { value: 'dev_product_gb_price_asc', label: 'Price low - high' },
          { value: 'dev_product_gb_price_desc', label: 'Price high - low' },
        ],
      },
      facets: [
        { name: 'Sort by', attribute: 'sortBy', component: 'sort' },
        { name: 'Arrangement type', attribute: 'arrangementType', component: 'terms' },
        { name: 'Contains alcohol', attribute: 'arrangementType', component: 'terms' },
        { name: 'Delivery type', attribute: 'deliveryTypes', component: 'terms' },
        { name: 'Flower Colour', attribute: 'flowerColour', component: 'terms', searchBox: true },
        { name: 'Content', attribute: 'flowerName', component: 'terms' },
        { name: 'Occasion', attribute: 'occasions', component: 'terms' },
        { name: 'Product type', attribute: 'productTypeIdentifier', component: 'terms' },
        { name: 'Same day delivery', attribute: 'sameDay', component: 'terms' },
        { name: 'Price', attribute: 'price', component: 'terms' },
      ],
      locale: 'en',
      currency: 'GBP',
    },
    installation: {},
  }
})()
