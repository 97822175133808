import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import FadeIn from '../../../common/FadeIn/FadeIn'
import classes from './Product.module.scss'
import { currencyFormatPartial } from '../../utils/common'

type Props = {
  item: {
    image: string
    productName: string
    sku: string
    price: number
  }
}

const Product = (props: Props) => {
  return (
    <FadeIn className={classes.cardWrapper}>
      <Card className={classes.product}>
        <div className={classes.imageContainer}>
          <img src={props.item.image || '/images/image-icon.svg'} alt="props.item.productName" />
        </div>
        <CardContent className={classes.cardContent}>
          <h3>{props.item.productName}</h3>
          <div>
            <p>Sku: {props.item.sku}</p>
            <span>{currencyFormatPartial(props.item.price)}</span>
          </div>
        </CardContent>
      </Card>
    </FadeIn>
  )
}

export default Product
