import ExperimentSelector from 'extensions/ExperimentSelector'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AlgoliaQuerySelector from './extensions/AlgoliaQuerySelector'
import CommercetoolsProductSelector from './extensions/CommercetoolsProductSelector'
import useAmplienceSdk from './hooks/useAmplienceSdk'
import { Extension } from './types'

/**
 * Main component for the UI extensions.
 *
 * Ensures that the Amplience SDK is loaded before rendering out the
 * extension specific component. Bear in mind that if you've set
 * `REACT_APP_LOCAL_DEVELOPMENT` to `true` in your local `.env` file,
 * then this SDK will be a mocked version that can be found in the
 * `mocks/sdk.ts` file of each extension folder.
 */
function App() {
  const location = useLocation()
  const { sdk, loading } = useAmplienceSdk()
  const [extensionName, setExtensionName] = useState('')

  useEffect(() => {
    if (!loading && sdk) {
      const ext = new URLSearchParams(location.search).get('ext')
      setExtensionName(ext)
    }
  }, [loading, sdk, location])

  if (loading) {
    return <p>Loading Amplience SDK...</p>
  }

  if (extensionName) {
    console.log('Amplience SDK: extension found', extensionName)
    console.log('Amplience SDK: initial params from SDK', sdk.params)
  }

  switch (extensionName) {
    case Extension.ALGOLIA_QUERY_SELECTOR:
      return <AlgoliaQuerySelector amplienceSdk={sdk} />
    case Extension.COMMERCETOOLS_PRODUCT_SELECTOR:
      return <CommercetoolsProductSelector amplienceSdk={sdk} />
    case Extension.EXPERIMENT_SELECTOR:
      return <ExperimentSelector amplienceSdk={sdk} />
    default:
      return <p>Not a valid extension</p>
  }
}

export default App
