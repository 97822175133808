import React from 'react'
import _ from 'lodash'

import { ActionTypes } from './actionTypes'
import { Dispatch, SearchResults, Item, Commercetools, Initialise } from '../common/types'
import { globalReducer, GlobalState } from './reducers/global.reducer'

const initialState = {
  sdk: null,
  selectedItems: [],
  ctInstance: null,
  loading: false,
  error: null,
  touched: false,
  params: null,
}
type GlobalProviderProps = { children: React.ReactNode }

type InitialiseState = Initialise & {
  dispatch: Dispatch
}

type SaveProps = {
  sdk: any
  dispatch: Dispatch
  allowMultiple: boolean
  selectedItems: SearchResults
}

const GlobalStateContext = React.createContext<GlobalState | undefined>(undefined)
const GlobalDispatchContext = React.createContext<Dispatch | undefined>(undefined)

async function initialiseState(config: InitialiseState) {
  const { sdk, dispatch, ctInstance, params } = config
  dispatch({
    type: ActionTypes.INITIALISE,
    payload: {
      ctInstance,
      sdk,
      params,
    },
  })
  await getSelectedItems(dispatch, sdk, ctInstance)
}

async function getSelectedItems(dispatch: Dispatch, sdk: any, ctInstance: Commercetools) {
  let productKeys = await sdk.field.getValue()

  if (productKeys && !Array.isArray(productKeys)) {
    productKeys = [productKeys]
  }

  productKeys = _.filter(productKeys, (item: Item) => !_.isEmpty(item))
  if (productKeys.length) {
    try {
      dispatch({ type: ActionTypes.SET_LOADING, payload: true })
      const updatedItems = await ctInstance.getItems(productKeys)
      const reorderedItems = productKeys.map((key: string) => updatedItems.find((item) => item.key === key))
      dispatch({ type: ActionTypes.SET_SELECTED_ITEMS, payload: reorderedItems.filter((item: Item) => item) })
    } catch (error) {
      dispatch({ type: ActionTypes.SET_ERROR, payload: error })
    } finally {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false })
    }
  }
}

async function activateSave(config: SaveProps) {
  const { sdk, dispatch, selectedItems, allowMultiple } = config
  try {
    if (allowMultiple) {
      await sdk.field.setValue(selectedItems.map((item: Item) => item.key))
    } else {
      await sdk.field.setValue(selectedItems.length ? selectedItems[0].key : '')
    }
  } catch (error) {
    dispatch({ type: ActionTypes.SET_ERROR, payload: error })
  }
}

function DataProvider({ children }: GlobalProviderProps) {
  const [state, dispatch] = React.useReducer(globalReducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

const useGlobalState = () => {
  const context = React.useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalDataContext')
  }
  return context
}

const useGlobalDispatch = () => {
  const context = React.useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalDispatchContext')
  }
  return context
}

export { DataProvider, useGlobalState, useGlobalDispatch, activateSave, initialiseState }
