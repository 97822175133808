import CryptoJS from 'crypto-js'
import { useEffect, useState } from 'react'
import { AmplienceSDK, ConvertExperience } from '../common/types'

// Sign and send the request to convert using fetch.
function signAndSend(
  applicationId: string,
  secretKey: string,
  requestUrl: string,
  method: string,
  body: string | Record<string, any> = ''
) {
  const checkedBody = typeof body === 'string' ? body : JSON.stringify(body)
  const expiresTimestamp = (Math.floor(Date.now() / 1000) + 60).toString()
  const SignString = `${applicationId}\n${expiresTimestamp}\n${requestUrl}\n${checkedBody}`
  const hash = CryptoJS.HmacSHA256(SignString, secretKey)
  const hashInHex = CryptoJS.enc.Hex.stringify(hash)
  const Authorization = 'Convert-HMAC-SHA256 Signature=' + hashInHex

  const headers = {
    Authorization,
    'Content-Type': 'application/json',
    'Convert-Application-ID': applicationId,
    Expires: expiresTimestamp,
  }

  const requestOptions: RequestInit = checkedBody
    ? {
        method,
        headers,
        body: checkedBody,
      }
    : {
        method,
        headers: headers,
      }

  const url = new URL(requestUrl)
  return fetch(url.toString(), requestOptions).then((response) => response.json())
}
export const useConvertExperiences = (sdk: AmplienceSDK) => {
  const [experiences, setExperiences] = useState<ConvertExperience[]>([])

  // Get all Convert experience data from the project using the api key and secret
  useEffect(() => {
    signAndSend(
      sdk.params.installation.convert_api_key,
      sdk.params.installation.convert_api_key_secret,
      `${sdk.params.installation.covert_api_url}accounts/${sdk.params.installation.convert_account_id}/projects/${sdk.params.installation.convert_project_id}/experiences`,
      'POST',
      { expand: ['variations'], include: ['variations'] }
    ).then((result) => setExperiences(result.data))
  }, [])

  return {
    experiences,
  }
}
