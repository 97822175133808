import useMockAmplienceSdk from './useMockAmplienceSdk'
import useRealAmplienceSdk from './useRealAmplienceSdk'

export default function useAmplienceSdk() {
  const isLocalDevelopment = process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true'
  if (isLocalDevelopment) {
    return useMockAmplienceSdk()
  }
  return useRealAmplienceSdk()
}
