import React from 'react'
import { connectNumericMenu } from 'react-instantsearch-dom'
import { useGlobalDispatch, useGlobalState, saveFacets } from '../../../context/GlobalDataContext'

export const PriceFilter = ({ items, refine, attribute }: any) => {
  const state = useGlobalState()
  const dispatch = useGlobalDispatch()
  const itemSelected = (item: any) => {
    refine(item.value)
    saveFacets({ state, dispatch, attribute, value: item.value })
  }

  return (
    <ul className="filter">
      {items.map((item: any) => (
        <li key={item.value}>
          <input
            type="checkbox"
            data-testid={`price-${item.value}`}
            checked={item.isRefined}
            disabled={item.noRefinement}
            onChange={() => {
              itemSelected(item)
            }}
          />
          {item.label}
        </li>
      ))}
    </ul>
  )
}

export default connectNumericMenu(PriceFilter)
