import { init } from 'dc-extensions-sdk'
import { useEffect, useState } from 'react'

export default function useAmplienceSdk() {
  const [state, setState] = useState({ loading: true, sdk: null })

  useEffect(() => {
    ;(async function () {
      try {
        setState({
          loading: false,
          sdk: await init(),
        })
      } catch (e) {
        console.error('Unable to load Amplience SDK', e.toString())
      }
    })()
  }, [])

  return state
}
