import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Extension } from '../types'
import mockSdkForCps from '../extensions/CommercetoolsProductSelector/mocks/sdk'
import mockSdkForAqs from '../extensions/AlgoliaQuerySelector/mocks/sdk'
import mockSdkForExperiment from '../extensions/ExperimentSelector/mocks/sdk'

/**
 * Get the appropriate mock Amplience SDK, given an extension name
 */
function getMockSdkForExtension(extension: Extension) {
  switch (extension) {
    case Extension.COMMERCETOOLS_PRODUCT_SELECTOR:
      return mockSdkForCps
    case Extension.ALGOLIA_QUERY_SELECTOR:
      return mockSdkForAqs
    case Extension.EXPERIMENT_SELECTOR:
      return mockSdkForExperiment
  }
}

/**
 * Ensures that we always have the correct mocked SDK for the UI extension.
 */
export default function useMockAmplienceSdk() {
  const location = useLocation()
  const [state, setState] = useState({ loading: true, sdk: null })

  useEffect(() => {
    const ext = new URLSearchParams(location.search).get('ext')
    setState({
      loading: false,
      sdk: getMockSdkForExtension(ext as Extension),
    })
  }, [location])

  return state
}
