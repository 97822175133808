import React from 'react'
import { Typography } from '@material-ui/core'
import { useGlobalState } from '../../context/GlobalDataContext'
import { useSearchState } from '../../context/SearchContext'

export const PaginationSummary = () => {
  const globalState = useGlobalState()
  const state = useSearchState()
  const { sdk } = globalState
  const {
    pages: { currentPage, total },
  } = state
  const PAGE_SIZE = sdk.params.instance.PAGE_SIZE || 20

  const start = PAGE_SIZE * currentPage
  const first = start + 1
  const last = Math.min(start + PAGE_SIZE, total)

  return <Typography variant="body1">{`Showing ${first} to ${last} of ${total}`}</Typography>
}

export default PaginationSummary
