import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#039be5',
    },
    secondary: {
      main: '#1ab0f9',
    },
    error: {
      main: '#ff3366',
    },
  },
})
