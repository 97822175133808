import { ActionTypes } from './actionTypes'
import { Action, SortConfig } from '../common/types'

export type GlobalState = {
  sdk: any
  facetFilters: any
  facets: any
  query: string
  loading: boolean
  error: Error
  params: any
  initialised: boolean
  filterChanged: boolean
  sortConfig?: SortConfig
  indexName: string
}

export const globalReducer = (state: GlobalState, action: Action) => {
  switch (action.type) {
    case ActionTypes.INITIALISE: {
      const { sdk, params, initialised, facetFilters, query, sortConfig, indexName } = action.payload
      const facets = facetFilters
        .filter((item) => item.currentRefinement)
        .map((item) => ({ name: item.attribute, attribute: item.attribute, currentRefinement: item.currentRefinement }))
      return {
        ...state,
        sdk,
        params,
        initialised,
        facets,
        facetFilters,
        query,
        sortConfig,
        indexName,
      }
    }
    case ActionTypes.SET_LOADING: {
      return { ...state, loading: action.payload }
    }
    case ActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    case ActionTypes.SET_FACETS: {
      return { ...state, facets: action.payload, filterChanged: true }
    }
    case ActionTypes.SET_INDEX_NAME: {
      return { ...state, indexName: action.payload }
    }
    case ActionTypes.SET_QUERY_TEXT: {
      return { ...state, query: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
