import React from 'react'
import Filter from './Filter/Filter'
import { useGlobalState } from '../../context/GlobalDataContext'
import { SortConfig } from '../../common/types'
import ClearFilters from './ClearFilters/ClearFilters'

type Props = {
  key: string
  name: string
  attribute: string
  defaultRefinement: string
  currentRefinement: string
  component: string
  searchable: boolean
  locale: string
  currency: string
  searchBox: boolean
  sortConfig?: SortConfig
}

export const ProductFilters = () => {
  const state = useGlobalState()
  return (
    <>
      <ClearFilters />
      {state.facetFilters.map((item: Props) => (
        <Filter
          key={item.name}
          name={item.name}
          attribute={item.attribute}
          defaultRefinement={item.currentRefinement}
          component={item.component}
          searchable={item.searchBox}
          locale={state.params.locale}
          currency={state.params.currency}
          sortConfig={state.params.sortConfig}
          showMore={true}
          limit={10}
          showMoreLimit={15}
        />
      ))}
    </>
  )
}

export default ProductFilters
