import React from 'react'
import SearchBlock from './SearchBlock/SearchBlock'
import { useInitialise } from '../hooks/useInitialise'
import { DataProvider } from '../context/GlobalDataContext'
import PropTypes from 'prop-types'

import './AlgoliaQuerySelector.scss'

const AlgoliaQuerySelector = ({ amplienceSdk }) => {
  const { params, paramsLoading } = useInitialise(amplienceSdk)

  return (
    <>
      {paramsLoading ? (
        <p>Loading Algolia query selector...</p>
      ) : (
        <>
          {params && (
            <DataProvider>
              <SearchBlock sdk={amplienceSdk} initialParams={params} />
            </DataProvider>
          )}
        </>
      )}
    </>
  )
}

AlgoliaQuerySelector.propTypes = {
  amplienceSdk: PropTypes.any,
}

export default AlgoliaQuerySelector
