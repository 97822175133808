import React, { useEffect } from 'react'
import ProductFilter from '../ProductFilters/ProductFilters'
import { InstantSearch, Configure, SearchBox, InfiniteHits } from 'react-instantsearch-dom'
import { ActionTypes } from '../../context/actionTypes'
import Product from '../Product/Product'
import ShowError from '../../../common/ShowError/ShowError'
import classes from './SearchBlock.module.scss'
import 'instantsearch.css/themes/algolia.css'
import { useGlobalDispatch, useGlobalState, activateSave } from '../../context/GlobalDataContext'

type HitProps = {
  hit: {
    sku: string
    imageUrl: string
    price: number
    productName: string
  }
}

const SearchBlock = ({ sdk, initialParams }: any) => {
  const dispatch = useGlobalDispatch()
  const state = useGlobalState()
  const { facets, query, initialised, error, indexName } = state

  useEffect(() => {
    console.log('Amplience SDK: startAutoResizer')
    sdk.frame.startAutoResizer()
  }, [initialised])

  useEffect(() => {
    dispatch({
      type: ActionTypes.INITIALISE,
      payload: {
        sdk,
        params: initialParams.params,
        initialised: true,
        facetFilters: initialParams.facetFilters,
        query: initialParams.query,
        indexName: initialParams.indexName,
      },
    })
  }, [])

  const onChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    dispatch({ type: ActionTypes.SET_QUERY_TEXT, payload: value })
    activateSave({ sdk, dispatch, facets, query: value || '', indexName })
  }

  return (
    <>
      <ShowError error={error} onClose={() => dispatch({ type: ActionTypes.SET_ERROR, payload: null })} />
      {initialised && (
        <InstantSearch searchClient={initialParams.searchClient} indexName={initialParams.params.indexName}>
          <div className="search-panel">
            <div className={classes.filterContent}>
              <ProductFilter />
            </div>
            <div className="search-panel__results">
              <SearchBox
                className="searchbox"
                translations={{
                  placeholder: 'Search',
                }}
                defaultRefinement={query}
                onChange={onChangeSearchBox}
                onReset={onChangeSearchBox}
              />
              <Configure facetingAfterDistinct={true} hitsPerPage={8} removeWordsIfNoResults="allOptional" distinct />
              <InfiniteHits hitComponent={Hit} />
            </div>
          </div>
        </InstantSearch>
      )}
    </>
  )
}

const Hit = (props: HitProps) => {
  const item = {
    id: props.hit.sku,
    sku: props.hit.sku,
    productName: props.hit.productName,
    image: props.hit.imageUrl,
    price: props.hit.price,
  }

  return <Product key={item.id} item={item} />
}

export default SearchBlock
