export enum ActionTypes {
  INITIALISE = 'INITIALISE',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS',
  SET_SEARCH_LOADING = 'SET_SEARCH_LOADING',
  SET_SEARCH_RESULTS_UPDATED = 'SET_SEARCH_RESULTS_UPDATED',
  SET_PAGES = 'SET_PAGES',
  SET_SEARCH_TEXT = 'SET_SEARCH_TEXT',
  SET_SEARCH_ERROR = 'SET_SEARCH_ERROR',
  SET_TOUCHED = 'SET_TOUCHED',
  REORDER_ITEMS = 'REORDER_ITEMS',
  TOGGLE_ITEM = 'TOGGLE_ITEM',
}
