import React, { useState, useLayoutEffect } from 'react'
import { Clear } from '@material-ui/icons'
import {
  Card,
  CardActionArea,
  CardMedia,
  CardHeader,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { useGlobalState, useGlobalDispatch } from '../../context/GlobalDataContext'
import _ from 'lodash'
import FadeIn from '../../../common/FadeIn/FadeIn'
import { ActionTypes } from '../../context/actionTypes'
import './Product.scss'

interface StyleProps {
  isSelected: boolean
  readOnly: boolean
  hasImage: boolean
}

interface ProductProps {
  item: {
    id: string
    key: string
    name: string
    image: string
  }
  variant?: string
}

const styles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      transition: 'border-width 0.3s',
      border: ({ isSelected }) => (isSelected ? `1px solid ${theme.palette.grey[500]}` : 'none'),
      margin: ({ isSelected }) => (isSelected ? '6px' : theme.spacing(1)),
      opacity: ({ readOnly }) => (readOnly ? '0.6' : ''),
      pointerEvents: ({ readOnly }) => (readOnly ? 'none' : 'auto'),
    },
    cardWrapper: {
      height: '100%',
      display: 'flex',
    },
    thumbnail: {
      paddingBottom: '100%',
      marginTop: 'auto',
      backgroundColor: ({ hasImage }) => (hasImage ? 'transparent' : theme.palette.grey[100]),
    },
    removeBtn: {
      marginLeft: theme.spacing(1),
    },
  })
)

export const Product = ({ item, variant }: ProductProps) => {
  const state = useGlobalState()
  const globalDispatch = useGlobalDispatch()
  const [visible, setVisible] = useState(false)
  const isRemovable = variant === 'removable'

  const isSelected = Boolean(_.find(state.selectedItems, { key: item.key }))
  const { readOnly } = state.sdk.form
  const toggle = () => {
    globalDispatch({ type: ActionTypes.TOGGLE_ITEM, payload: { item, isSelected } })
    globalDispatch({ type: ActionTypes.SET_TOUCHED, payload: true })
  }

  const hideProduct = () => {
    setVisible(true)
    toggle()
  }

  useLayoutEffect(() => setVisible(true), [])

  const { key, name, image } = item

  const classes = styles({
    isSelected,
    readOnly,
    hasImage: Boolean(image),
  })

  const cardMedia = (
    <CardMedia className={classes.thumbnail} image={image || '/images/image-icon.svg'} title={name}></CardMedia>
  )

  const CardAction = ({ productKey }: { productKey: string }) => (
    <IconButton
      aria-label="Remove"
      onClick={hideProduct}
      className={classes.removeBtn}
      data-testid={`remove-${productKey}`}
    >
      <Clear />
    </IconButton>
  )

  const cardBody = isRemovable ? cardMedia : <CardActionArea>{cardMedia}</CardActionArea>

  return (
    <FadeIn show={visible} className={classes.cardWrapper}>
      <Card className={'product ' + classes.root} raised={isSelected} onClick={isRemovable ? null : toggle}>
        <CardHeader
          action={isRemovable && <CardAction productKey={key} />}
          title={name}
          subheader={`(${key})`}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheaderTypographyProps={{ variant: 'body2' }}
        />
        {cardBody}
      </Card>
    </FadeIn>
  )
}

export default Product
