import algoliasearch from 'algoliasearch/lite'
import { useState, useEffect } from 'react'

export const useInitialise = (sdk) => {
  const [params, setParams] = useState(null)
  const [paramsLoading, setParamsLoading] = useState(true)

  const initialiseParams = async () => {
    const sdkParams = {
      ...sdk.params.instance,
      ...sdk.params.installation,
    }
    try {
      const fieldValue = await sdk.field.getValue()
      const { facets, sortConfig } = sdkParams
      let retrievedFacets = facets

      if (fieldValue.facetFilters && facets) {
        const facetsConsolidated = facets.map((item: any) => {
          const facetFounded = fieldValue.facetFilters.find((facet: any) => facet.attribute === item.attribute)
          if (facetFounded) {
            item.currentRefinement = facetFounded.currentRefinement
          }
          return item
        })
        retrievedFacets = facetsConsolidated
      }

      setParams({
        searchClient: algoliasearch(sdkParams.appId, sdkParams.apiKey),
        paramsInitialised: true,
        sortConfig,
        facetFilters: retrievedFacets,
        query: fieldValue.query || '',
        indexName: fieldValue.indexName || sdkParams.indexName,
        params: sdkParams,
      })
    } catch (e) {
      console.log(e)
    } finally {
      setParamsLoading(false)
    }
  }

  useEffect(() => {
    initialiseParams()
  }, [])

  return {
    sdk,
    params,
    paramsLoading,
  }
}
