import _ from 'lodash'
import { ActionTypes } from '../actionTypes'
import { Action, SearchResults } from '../../common/types'
import Commercetools from '../../lib/Commercetools'

export type GlobalState = {
  sdk: any
  selectedItems: SearchResults
  loading: boolean
  error: Error
  ctInstance: Commercetools
  touched: boolean
  params: any
}

export const globalReducer = (state: GlobalState, action: Action) => {
  switch (action.type) {
    case ActionTypes.INITIALISE: {
      const { ctInstance, sdk, params } = action.payload
      return {
        ...state,
        ctInstance,
        sdk,
        params,
      }
    }
    case ActionTypes.SET_LOADING: {
      return { ...state, loading: action.payload }
    }
    case ActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    case ActionTypes.SET_SELECTED_ITEMS: {
      return { ...state, selectedItems: action.payload }
    }
    case ActionTypes.REORDER_ITEMS: {
      const { oldIndex, newIndex } = action.payload
      const itemToMove = state.selectedItems[oldIndex]
      const remainingItems = _.reject(state.selectedItems, { id: itemToMove.id })
      const reorderedItems = [..._.slice(remainingItems, 0, newIndex), itemToMove, ..._.slice(remainingItems, newIndex)]
      return { ...state, selectedItems: reorderedItems }
    }
    case ActionTypes.TOGGLE_ITEM: {
      const { item, isSelected } = action.payload
      const existingItems = state.params.allowMultiple ? [...state.selectedItems] : []
      const selectedItems = isSelected ? _.reject(state.selectedItems, { id: item.id }) : [...existingItems, item]
      return { ...state, selectedItems }
    }
    case ActionTypes.SET_TOUCHED: {
      return { ...state, touched: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
