import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

type Props = {
  error?: Error
  onClose: () => void
}

const ShowError = ({ error, onClose }: Props) => {
  const message = (
    <span>
      <Warning />
      {error && error.toString()}
    </span>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={() => onClose()}
      open={Boolean(error)}
      message={message}
    />
  )
}

export default ShowError
