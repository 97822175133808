import React from 'react'
import { ButtonGroup, Button, makeStyles } from '@material-ui/core'
import { SkipNext, SkipPrevious, ArrowRight, ArrowLeft } from '@material-ui/icons'
import { useGlobalState } from '../../context/GlobalDataContext'
import { useSearchState, useSearchDispatch, searchItems } from '../../context/SearchContext'
import _ from 'lodash'

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

export const Pager = ({ searchText }: { searchText: string }) => {
  const classes = styles()
  const state = useSearchState()
  const globalState = useGlobalState()
  const dispatch = useSearchDispatch()
  const { ctInstance } = globalState
  const {
    pages: { numPages, currentPage },
  } = state
  const NUM_START_PAGES = 3
  const NUM_END_PAGES = 3
  const NUM_VISIBLE_PAGES = 6
  const allPages = _.range(numPages)
  let pages = []

  const changePage = (pageNumber: number) => {
    const params = {
      searchText,
      pages: {
        ...state.pages,
        currentPage: pageNumber,
      },
    }
    searchItems(dispatch, ctInstance, params)
  }

  if (currentPage - NUM_START_PAGES < 0) {
    pages = _.take(allPages, NUM_VISIBLE_PAGES)
  } else if (currentPage + NUM_END_PAGES > numPages) {
    pages = _.takeRight(allPages, NUM_VISIBLE_PAGES)
  } else {
    const startPages = _.range(currentPage - NUM_START_PAGES, currentPage)
    const endPages = _.range(currentPage, currentPage + NUM_END_PAGES)
    pages = _.take([...startPages, ...endPages], NUM_VISIBLE_PAGES)
  }

  return (
    <ButtonGroup color="primary" className={classes.root}>
      <Button aria-label="first" onClick={() => changePage(0)} disabled={currentPage === 0}>
        <SkipPrevious fontSize="small" />
      </Button>
      <Button aria-label="previous" onClick={() => changePage(currentPage - 1)} disabled={currentPage === 0}>
        <ArrowLeft fontSize="small" />
      </Button>
      {pages.map((page) => (
        <Button variant={page === currentPage ? 'contained' : null} key={page} onClick={() => changePage(page)}>
          {page + 1}
        </Button>
      ))}
      <Button aria-label="next" onClick={() => changePage(currentPage + 1)} disabled={currentPage === numPages - 1}>
        <ArrowRight fontSize="small" />
      </Button>
      <Button aria-label="last" onClick={() => changePage(numPages - 1)} disabled={currentPage === numPages - 1}>
        <SkipNext fontSize="small" />
      </Button>
    </ButtonGroup>
  )
}

export default Pager
