import Commercetools from '../lib/Commercetools'
import { useState, useEffect } from 'react'

export const useInitialise = (amplienceSdk) => {
  const [ctInstance, setCtInstance] = useState(null)
  const [params, setParams] = useState(null)

  useEffect(() => {
    const instanceParams = amplienceSdk.params.instance
    const installationParams = amplienceSdk.params.installation
    const params = {
      pageSize: 20,
      allowMultiple: true,
      ...instanceParams,
      ...installationParams,
    }
    setCtInstance(new Commercetools(params))
    setParams(params)
  }, [])

  return {
    ctInstance,
    params,
  }
}
