import React, { useEffect, useState } from 'react'
import { FieldProps } from '../../common/types'

export const ExperienceField = ({ experiences, amplienceSdk }: FieldProps) => {
  const [selectedExperienceId, setSelectedExperienceId] = useState('')

  // Get the initial value from Amplience
  useEffect(() => {
    amplienceSdk.field.getValue().then((result) => setSelectedExperienceId(result))
  }, [])

  // Update Amplience field value when the id is updated in state and store it in local storage to update the variation fields
  useEffect(() => {
    amplienceSdk.field.setValue(selectedExperienceId || '')
    window.localStorage.setItem('experienceId', selectedExperienceId || '')
  }, [selectedExperienceId])

  return (
    <>
      {experiences?.length ? (
        <>
          <select
            name="experience"
            value={selectedExperienceId}
            onChange={(e) => setSelectedExperienceId(e.target.value)}
          >
            <option value={''}>Please select an experiment</option>
            {experiences.map((experience) => (
              <option key={experience.id} value={experience.id}>
                {experience.name}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>No Experiences Found</>
      )}
      <br />
      Experience Id:
      <input type="text" value={selectedExperienceId} onChange={(e) => setSelectedExperienceId(e.target.value)} />
    </>
  )
}

export default ExperienceField
