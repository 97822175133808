export default new (function () {
  this.field = {
    getValue: () => {
      console.info('Amplience SDK: getValue')
      return []
    },
    setValue: (value) => {
      console.info('Amplience SDK: setValue:', value)
    },
  }

  this.frame = {
    startAutoResizer: () => {
      console.info('Amplience SDK: startAutoResizer')
    },
  }

  this.form = {
    readOnly: false,
  }

  this.params = {
    instance: {
      projectKey: process.env.REACT_APP_CPS_PROJECT_KEY,
      clientId: process.env.REACT_APP_CPS_CLIENT_ID,
      clientSecret: process.env.REACT_APP_CPS_CLIENT_SECRET,
      locale: 'en',
    },
    installation: {},
  }
})()
