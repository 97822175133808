import React, { useEffect, useState } from 'react'
import { FieldProps } from '../../common/types'
import styles from './ExperimentSelector.module.scss'

export const VariationField = ({ experiences, amplienceSdk }: FieldProps) => {
  const [selectedExperienceId, setSelectedExperienceId] = useState('')
  const [selectedVariationId, setSelectedVariationId] = useState('')
  const selectedExperience = experiences?.find((experience) => `${experience.id}` === selectedExperienceId)

  // Register a listener for when the experience id is updated in local storage, to update which variation options to show
  useEffect(() => {
    const refreshExperiment = () => {
      const experienceId = window.localStorage.getItem('experienceId')
      if (!experienceId || experienceId !== selectedExperienceId) {
        setSelectedExperienceId(experienceId || '')
      }
    }
    window.addEventListener('storage', refreshExperiment)
    return () => window.removeEventListener('storage', refreshExperiment)
  }, [])

  // Get the initial value from Amplience, and get the experience id from local storage
  useEffect(() => {
    setSelectedExperienceId(window.localStorage.getItem('experienceId') || '')
    amplienceSdk.field.getValue().then((result) => setSelectedVariationId(result))
  }, [])

  // Update the Amplience field value when the id is updated in state
  useEffect(() => {
    amplienceSdk.field.setValue(selectedVariationId)
  }, [selectedVariationId])

  return (
    <div className={styles.container}>
      {selectedExperience?.variations?.length ? (
        <>
          <select name="variation" value={selectedVariationId} onChange={(e) => setSelectedVariationId(e.target.value)}>
            <option value={''}>Please select a variation</option>
            {selectedExperience?.variations?.map((variation) => (
              <option key={variation.id} value={variation.id}>
                {variation.name || variation.id}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>Please Select A Valid Experiment</>
      )}
      <br />
      Variant Id:{' '}
      <input type="text" value={selectedVariationId} onChange={(e) => setSelectedVariationId(e.target.value)} />
    </div>
  )
}

export default VariationField
