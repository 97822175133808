import React from 'react'
import { DataProvider } from '../context/GlobalDataContext'
import CommercetoolsProductSelector from './CommercetoolsProductSelector'
import { useInitialise } from '../hooks/useInitialise'
import PropTypes from 'prop-types'

const ComponentContextWrapper = ({ amplienceSdk }) => {
  const { params, ctInstance } = useInitialise(amplienceSdk)
  return (
    <>
      {ctInstance && (
        <DataProvider>
          <CommercetoolsProductSelector sdk={amplienceSdk} ctInstance={ctInstance} params={params} />
        </DataProvider>
      )}
    </>
  )
}

ComponentContextWrapper.propTypes = {
  amplienceSdk: PropTypes.any,
}

export default ComponentContextWrapper
