import React from 'react'
import Commercetools from '../lib/Commercetools'
import { Dispatch, SearchParams } from '../common/types'
import { searchReducer, SearchState } from './reducers/search.reducer'
import { ActionTypes } from './actionTypes'

const initialState = {
  pages: {
    currentPage: 0,
    numPages: 0,
    total: 0,
  },
  searchResults: [],
  searchText: '',
  searchLoading: false,
  searchError: null,
}

type GlobalProviderProps = { children: React.ReactNode }

const SearchStateContext = React.createContext<SearchState>(undefined)
const SearchDispatchContext = React.createContext<Dispatch>(undefined)

async function searchItems(dispatch: Dispatch, ctInstance: Commercetools, params: SearchParams) {
  dispatch({ type: ActionTypes.SET_SEARCH_LOADING, payload: true })
  try {
    const { items: fetchedItems, pages } = await ctInstance.search(params)
    dispatch({ type: ActionTypes.SET_SEARCH_RESULTS_UPDATED, payload: fetchedItems })
    dispatch({ type: ActionTypes.SET_PAGES, payload: pages })
  } catch (error) {
    dispatch({ type: ActionTypes.SET_SEARCH_ERROR, payload: error })
  } finally {
    dispatch({ type: ActionTypes.SET_SEARCH_LOADING, payload: false })
  }
}

function SearchProvider({ children }: GlobalProviderProps) {
  const [state, dispatch] = React.useReducer(searchReducer, initialState)
  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>{children}</SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  )
}

const useSearchState = () => {
  const context = React.useContext(SearchStateContext)
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchStateContext')
  }
  return context
}

const useSearchDispatch = () => {
  const context = React.useContext(SearchDispatchContext)
  if (context === undefined) {
    throw new Error('useSearchDispatch must be used within a SearchDispatchContext')
  }
  return context
}

export { SearchProvider, searchItems, useSearchState, useSearchDispatch }
